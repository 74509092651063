import React from "react";
import Layout from '../components/layout';
import TalkCard from '../components/talkCard';

export default () => {
  return (
    <Layout>
      <div class="content">
        <h1>Charlas</h1>

        <ul>
          <li><TalkCard talk={{url: 'https://youtu.be/DCpu3AAWdd4', name: 'Calzada Code Talks 6 - Cookies' }} /></li>
          <li><TalkCard talk={{url: 'https://youtu.be/JH-PBkESWW0', name: 'Calzada Code Talks 6 - Código empático' }} /></li>
          <li><TalkCard talk={{url: 'https://youtu.be/AzXQH_qcp7E', name: 'Calzada Code Talks 5 - Intersection Observer' }} /></li>
          <li><TalkCard talk={{url: 'https://youtu.be/1eiQ4qUWFuM', name: 'Calzada Code Talks 5 - Agrupando con k-means' }} /></li>
        </ul>
      </div>
    </Layout>
  );
}
