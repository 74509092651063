import React from 'react';
import PropTypes from 'prop-types';

const TalkCard = ({talk}) => (
  <a
    href={ talk.url }
    target='_blank'
    rel='noopener noreferrer'>
    { talk.name }
  </a>
)

TalkCard.propTypes = {
  talk: PropTypes.object
}

export default TalkCard;
